<template>
  <div class="courses">
    <header-search
      :title="$t('courses.title')"
      @search="handleFilters($event)"
      :filters="filters"
    />

    <div class="container">
      <section class="courses__grid">
        <ul v-if="courses.length > 0" class="courses__grid-list" ref="tileList">
          <course-tile
            v-for="course in courses"
            :key="course.id"
            :course="course"
            class="courses__tile"
            :class="{ 'courses__tile--animate': course.id }"
            :isSold="ownedCoursesIds.includes(course.id)"
            withObserver
          />
        </ul>
        <p class="courses__grid-error" v-if="noCourses && !loading">
          {{ $t("courses.noCourses") }}
        </p>
      </section>

      <div class="courses-trigger" ref="list">
        trigger
      </div>
      <span class="courses__loading" v-if="loading"/>
    </div>
  </div>
</template>

<script>
import course from '@/api/services/course';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CoursesPage',
  metaInfo: {
    title: 'Edunails - Online Nail Art Course Browser',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Browse our vast selection of nail art training, from beginner to expert level, including courses on combined & hybrid manicure; pedicure, shellac, gel & acrylic training.',
      },
    ],
  },
  data: () => ({
    loading: false,
    observer: null,
    skip: 0,
    prevSkip: -1,
    limit: 12,
    courses: [],
    filters: {
      manicure_methods: [],
      shapes: [],
      art: [],
      languages: [],
      difficulties: [],
    },
    fetchedFilters: {},
    searchQuery: '',
    areFetchedAllCourses: false,
    ownedCoursesIds: [],
  }),
  watch: {
    filters: {
      deep: true,
      handler() {
        this.setFiltersAsString();
        this.resetParams();
      },
    },
    $route() {
      this.searchQuery = this.$route.query.q;
      this.resetParams();
      this.setFiltersAsString();
      this.fetchCourses({ resetScrolledCourses: true });
    },
  },
  methods: {
    resetParams() {
      this.skip = 0;
      this.limit = 12;
      this.areFetchedAllCourses = false;
    },
    setFiltersAsString() {
      const filters = {};
      Object.entries(this.filters).forEach(([key, value]) => {
        if (key === 'min_price' || key === 'max_price') {
          if (typeof value === 'number') {
            filters[key] = value;
          }
        } else if (value.length > 0) {
          filters[key] = value.join(',');
        }
      });
      this.fetchedFilters = filters;
    },
    handleFilters(filters) {
      this.skip = 0;
      this.limit = 12;
      this.filters = filters;
      if (this.searchQuery) {
        this.$router.push('/courses');
        return;
      }
      setTimeout(() => {
        this.fetchCourses({ resetScrolledCourses: true });
      }, 0);
    },
    async fetchCourses({ resetScrolledCourses } = false) {
      if (resetScrolledCourses) {
        this.$store.dispatch('ui/resetScrolledCourses');
      }
      this.$store.dispatch('ui/setPreloader', true);
      this.limit = this.scrolledCourses ? this.scrolledCourses : this.limit;
      try {
        const { data } = await course.getCourses({
          skip: this.skip,
          limit: this.limit,
          q: this.searchQuery,
          ...this.fetchedFilters,
        });
        this.courses = data;
        this.skip += this.limit;
        if (data.length < this.limit) {
          this.areFetchedAllCourses = true;
        }
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
        this.$root.$emit('scrollBeforeEnter');
      }
    },
  },
  computed: {
    noCourses() {
      return this.courses.length === 0;
    },
    ...mapState({
      scrolledCourses: (state) => state.ui.scrolledCourses,
    }),
    ...mapGetters({
      isLogged: 'auth/isLogged',
    }),
  },
  async mounted() {
    if (this.isLogged) {
      const owned = await course.getOwnedCourses();
      this.ownedCoursesIds = owned.data.filter((x) => x.paymentStatus === 'paid').map((x) => x.courseId);
    }

    if (Object.keys(this.$route.params).length > 0) {
      this.filters = this.$route.params;
    }

    this.setFiltersAsString();
    this.searchQuery = this.$route.query.q;
    this.$store.dispatch('ui/setPreloader', true);
    await this.fetchCourses();
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          const fetchCourses = async () => {
            this.loading = true;
            const { skip } = this;
            const { limit } = this;
            const response = await course.getCourses({
              skip,
              limit,
              ...this.fetchedFilters,
              q: this.searchQuery,
            });
            this.courses = [...this.courses, ...response.data];
            this.loading = false;
            this.skip += this.limit;
            this.$store.dispatch('ui/setScrolledCourses', this.skip);
            if (response.data.length < this.limit) {
              this.areFetchedAllCourses = true;
            }
          };
          if (!this.areFetchedAllCourses) {
            fetchCourses();
          }
        }
      },
      { rootMargin: '200px' },
    );

    this.observer.observe(this.$refs.list);
  },
};
</script>

<style lang="scss" scoped src="./Courses.scss"/>
